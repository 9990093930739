@import url('https://fonts.cdnfonts.com/css/uk-number-plate');

.reg_plate_wrapper {
  display: flex;
  justify-content: center; /* Center align */
  height: 62px;
  position: relative;
  border-radius: 4px;
  background: none; /* No background */
  border: none; /* No border */
  width: 100%;
}

.reg_plate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  color: #000 !important;
  text-align: center;
  white-space: nowrap;
  font-size: 45px !important;
  font-family: 'UKNumberPlate', monospace;
  background: #f6d11e; /* Set to the yellow color directly */
  width: 240px;
}

.reg_plate--back {
  margin: 0;
  border: none; /* Removed the border */
}

.reg_plate--front {
  background: #fff;
  margin-bottom: 12px;
  border: none; /* Removed the border */
}
