.modalContainer {
  position: fixed;
  z-index: 1200;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: ln(20);
  background: rgba(0, 0, 0, 0);
  overflow: auto;
}